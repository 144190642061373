/* eslint-disable */
//process.env.VUE_APP_NODE_ENV this is how we obtain the setting for vue
let config = {};
let hostname = window.location.hostname;

config.BASE_DOMAIN = hostname.split('.').splice(-2).join('.');
console.log(hostname);
//PROD ENV JUPITERHQ
if (hostname === 'backoffice.jupiterhq.com' || hostname === 'payments.jupiterhq.com') {
  config.ENV = 'production';
  config.ROOT_API = 'https://payments-api.jupiterhq.com/api/v1';
  config.HOSTED_PAGES_URL = 'https://payments-hosted-pages.jupiterhq.com';
  config.MICROSERVICES_URL = 'https://platform.jupiterhq.com/v1';
  config.ONBOARDING_URL = 'https://payments-onboarding.jupiterhq.com';
  config.PAYMENT_BACKOFFICE_URL = 'https://payments.jupiterhq.com';
  config.CAPITAL_BACKOFFICE_URL = 'https://capital.jupiterhq.com';
  config.COMMONS_BACKOFFICE_URL = 'https://accounts.jupiterhq.com';
  config.ADMIN_BACKOFFICE_URL = 'https://admin.jupiterhq.com';
  config.VGS_VAULT = 'tntaxfujub5';
  config.VGS_SCRIPT = 'https://js.verygoodvault.com/vgs-collect/2.7.0/vgs-collect.js';
  config.TOKENIZATION_URL = '/v1/transactions/creditcard/tokenization/';
  config.BYPASS_COMMONS_LOGIN = false;
  config.MIDDESK_BASE_BUSINESS_URL = 'https://app.middesk.com/businesses';
  document.title = 'Jupico Backoffice';
}
//PROD JUPICO
else if (hostname === 'backoffice.jupico.com' || hostname === 'payments.jupico.com') {
  config.ENV = 'production';
  config.ROOT_API = 'https://payments-api.jupico.com/api/v1';
  config.HOSTED_PAGES_URL = 'https://payments-hosted-pages.jupico.com';
  config.MICROSERVICES_URL = 'https://platform.jupico.com/v1';
  config.ONBOARDING_URL = 'https://payments-onboarding.jupico.com';
  config.PAYMENT_BACKOFFICE_URL = 'https://payments.jupico.com';
  config.CAPITAL_BACKOFFICE_URL = 'https://capital.jupico.com';
  config.COMMONS_BACKOFFICE_URL = 'https://accounts.jupico.com';
  config.ADMIN_BACKOFFICE_URL = 'https://admin.jupico.com';
  config.VGS_VAULT = 'tntaxfujub5';
  config.VGS_SCRIPT = 'https://js.verygoodvault.com/vgs-collect/2.7.0/vgs-collect.js';
  config.TOKENIZATION_URL = '/v1/transactions/creditcard/tokenization/';
  config.BYPASS_COMMONS_LOGIN = false;
  config.MIDDESK_BASE_BUSINESS_URL = 'https://app.middesk.com/businesses';
  document.title = 'Jupico Backoffice';
}
//SANDBOX JUPITERHQ
else if (hostname === 'sandbox-payments.jupiterhq.com') {
  config.ENV = 'sandbox';
  config.ROOT_API = 'https://sandbox-payments-api.jupiterhq.com/api/v1';
  config.HOSTED_PAGES_URL = 'https://sandbox-payments-hosted-pages.jupiterhq.com';
  config.MICROSERVICES_URL = 'https://sandbox-platform.jupiterhq.com/v1';
  config.ONBOARDING_URL = 'https://sandbox-payments-onboarding.jupiterhq.com';
  config.PAYMENT_BACKOFFICE_URL = 'https://sandbox-payments.jupiterhq.com';
  config.CAPITAL_BACKOFFICE_URL = 'https://sandbox-capital.jupiterhq.com';
  config.COMMONS_BACKOFFICE_URL = 'https://sandbox-accounts.jupiterhq.com';
  config.ADMIN_BACKOFFICE_URL = 'https://sandbox-admin.jupiterhq.com';
  config.VGS_VAULT = 'tntne5koztu';
  config.VGS_SCRIPT = 'https://js.verygoodvault.com/vgs-collect/2.7.0/vgs-collect.js';
  config.TOKENIZATION_URL = '/v1/transactions/creditcard/tokenization/';
  config.BYPASS_COMMONS_LOGIN = false;
  config.MIDDESK_BASE_BUSINESS_URL = 'https://app.middesk.com/businesses';
  document.title = 'Jupico Backoffice';
}
//SANDBOX JUPICO
else if (hostname === 'sandbox-payments.jupico.com') {
  config.ENV = 'sandbox';
  config.ROOT_API = 'https://sandbox-payments-api.jupico.com/api/v1';
  config.HOSTED_PAGES_URL = 'https://sandbox-payments-hosted-pages.jupico.com';
  config.MICROSERVICES_URL = 'https://sandbox-platform.jupico.com/v1';
  config.ONBOARDING_URL = 'https://sandbox-payments-onboarding.jupico.com';
  config.PAYMENT_BACKOFFICE_URL = 'https://sandbox-payments.jupico.com';
  config.CAPITAL_BACKOFFICE_URL = 'https://sandbox-capital.jupico.com';
  config.COMMONS_BACKOFFICE_URL = 'https://sandbox-accounts.jupico.com';
  config.ADMIN_BACKOFFICE_URL = 'https://sandbox-admin.jupico.com';
  config.VGS_VAULT = 'tntne5koztu';
  config.VGS_SCRIPT = 'https://js.verygoodvault.com/vgs-collect/2.7.0/vgs-collect.js';
  config.TOKENIZATION_URL = '/v1/transactions/creditcard/tokenization/';
  config.BYPASS_COMMONS_LOGIN = false;
  config.MIDDESK_BASE_BUSINESS_URL = 'https://app.middesk.com/businesses';
  document.title = 'Jupico Backoffice';
}
//SANDBOX PAY FAC
else if (hostname === 'sandbox-payments.payfac.io') {
  config.ENV = 'sandbox';
  config.ROOT_API = 'https://sandbox-payments-api.payfac.io/api/v1';
  config.HOSTED_PAGES_URL = 'https://sandbox-payments-hosted-pages.payfac.io';
  config.MICROSERVICES_URL = 'https://sandbox-platform.payfac.io/v1';
  config.ONBOARDING_URL = 'https://sandbox-payments-onboarding.payfac.io';
  config.PAYMENT_BACKOFFICE_URL = 'https://sandbox-payments.payfac.io';
  config.CAPITAL_BACKOFFICE_URL = 'https://sandbox-capital.payfac.io';
  config.COMMONS_BACKOFFICE_URL = 'https://sandbox-accounts.payfac.io';
  config.ADMIN_BACKOFFICE_URL = 'https://sandbox-admin.payfac.io';
  config.VGS_VAULT = 'tntze488mkz';
  config.VGS_SCRIPT = 'https://js.verygoodvault.com/vgs-collect/2.7.0/vgs-collect.js';
  config.TOKENIZATION_URL = '/v1/transactions/creditcard/tokenization/';
  config.BYPASS_COMMONS_LOGIN = false;
  config.MIDDESK_BASE_BUSINESS_URL = 'https://app.middesk.com/businesses';
  document.title = 'Payfac Backoffice';
}
//STAGE JUPITERHQ
else if (hostname === 'stage-payments.jupiterhq.com') {
  config.ENV = 'stage';
  config.ROOT_API = 'https://stage-payments-api.jupiterhq.com/api/v1';
  config.HOSTED_PAGES_URL = 'https://stage-payments-hosted-pages.jupiterhq.com';
  config.MICROSERVICES_URL = 'https://stage-platform.jupiterhq.com/v1';
  config.ONBOARDING_URL = 'https://stage-payments-onboarding.jupiterhq.com';
  config.PAYMENT_BACKOFFICE_URL = 'https://stage-payments.jupiterhq.com';
  config.CAPITAL_BACKOFFICE_URL = 'https://stage-capital.jupiterhq.com';
  config.COMMONS_BACKOFFICE_URL = 'https://stage-accounts.jupiterhq.com';
  config.ADMIN_BACKOFFICE_URL = 'https://stage-admin.jupiterhq.com';
  config.VGS_VAULT = 'tntex03p3du';
  config.VGS_SCRIPT = 'https://js.verygoodvault.com/vgs-collect/2.7.0/vgs-collect.js';
  config.TOKENIZATION_URL = '/v1/transactions/creditcard/tokenization/';
  config.BYPASS_COMMONS_LOGIN = false;
  config.MIDDESK_BASE_BUSINESS_URL = 'https://app.middesk.com/businesses';
  document.title = 'Jupico Backoffice';
}
//STAGE JUPICO
else if (hostname === 'stage-payments.jupico.com') {
  config.ENV = 'stage';
  config.ROOT_API = 'https://stage-payments-api.jupico.com/api/v1';
  config.HOSTED_PAGES_URL = 'https://stage-payments-hosted-pages.jupico.com';
  config.MICROSERVICES_URL = 'https://stage-platform.jupico.com/v1';
  config.ONBOARDING_URL = 'https://stage-payments-onboarding.jupico.com';
  config.PAYMENT_BACKOFFICE_URL = 'https://stage-payments.jupico.com';
  config.CAPITAL_BACKOFFICE_URL = 'https://stage-capital.jupico.com';
  config.COMMONS_BACKOFFICE_URL = 'https://stage-accounts.jupico.com';
  config.ADMIN_BACKOFFICE_URL = 'https://stage-admin.jupico.com';
  config.VGS_VAULT = 'tntex03p3du';
  config.VGS_SCRIPT = 'https://js.verygoodvault.com/vgs-collect/2.7.0/vgs-collect.js';
  config.TOKENIZATION_URL = '/v1/transactions/creditcard/tokenization/';
  config.BYPASS_COMMONS_LOGIN = false;
  config.MIDDESK_BASE_BUSINESS_URL = 'https://app.middesk.com/businesses';
  document.title = 'Jupico Backoffice';
}
//STAGE PAY FAC
else if (hostname === 'stage-payments.payfac.io') {
  config.ENV = 'stage';
  config.ROOT_API = 'https://stage-payments-api.payfac.io/api/v1';
  config.HOSTED_PAGES_URL = 'https://stage-payments-hosted-pages.payfac.io';
  config.MICROSERVICES_URL = 'https://stage-platform.payfac.io/v1';
  config.ONBOARDING_URL = 'https://stage-payments-onboarding.payfac.io';
  config.PAYMENT_BACKOFFICE_URL = 'https://stage-payments.payfac.io';
  config.CAPITAL_BACKOFFICE_URL = 'https://stage-capital.payfac.io';
  config.COMMONS_BACKOFFICE_URL = 'https://stage-accounts.payfac.io';
  config.ADMIN_BACKOFFICE_URL = 'https://stage-admin.payfac.io';
  config.VGS_VAULT = 'tntex03p3du';
  config.VGS_SCRIPT = 'https://js.verygoodvault.com/vgs-collect/2.7.0/vgs-collect.js';
  config.TOKENIZATION_URL = '/v1/transactions/creditcard/tokenization/';
  config.BYPASS_COMMONS_LOGIN = false;
  config.MIDDESK_BASE_BUSINESS_URL = 'https://app.middesk.com/businesses';
  document.title = 'Payfac Backoffice';
} else if (hostname === 'dev-payments.jupiterhq.com') {
  config.ENV = 'development';
  config.ROOT_API = 'https://dev-payments-api.jupiterhq.com/api/v1';
  config.HOSTED_PAGES_URL = 'https://dev-payments-hosted-pages.jupiterhq.com';
  config.MICROSERVICES_URL = 'https://dev-platform.jupiterhq.com/v1';
  config.ONBOARDING_URL = 'https://dev-payments-onboarding.jupiterhq.com';
  config.PAYMENT_BACKOFFICE_URL = 'https://dev-payments.jupiterhq.com';
  config.CAPITAL_BACKOFFICE_URL = 'https://dev-capital.jupiterhq.com';
  config.COMMONS_BACKOFFICE_URL = 'https://dev-accounts.jupiterhq.com';
  config.ADMIN_BACKOFFICE_URL = 'https://dev-admin.jupiterhq.com';
  config.VGS_VAULT = 'tnthkove3uq';
  config.VGS_SCRIPT = 'https://js.verygoodvault.com/vgs-collect/2.7.0/vgs-collect.js';
  config.TOKENIZATION_URL = '/v1/transactions/creditcard/tokenization/';
  config.BYPASS_COMMONS_LOGIN = false;
  config.MIDDESK_BASE_BUSINESS_URL = 'https://app.middesk.com/businesses';
  document.title = 'Jupico Backoffice';
}
//DEV JUPICO
else if (hostname === 'dev-payments.jupico.com') {
  config.ENV = 'development';
  config.ROOT_API = 'https://dev-payments-api.jupico.com/api/v1';
  config.HOSTED_PAGES_URL = 'https://dev-payments-hosted-pages.jupico.com';
  config.MICROSERVICES_URL = 'https://dev-platform.jupico.com/v1';
  config.ONBOARDING_URL = 'https://dev-payments-onboarding.jupico.com';
  config.PAYMENT_BACKOFFICE_URL = 'https://dev-payments.jupico.com';
  config.CAPITAL_BACKOFFICE_URL = 'https://dev-capital.jupico.com';
  config.COMMONS_BACKOFFICE_URL = 'https://dev-accounts.jupico.com';
  config.ADMIN_BACKOFFICE_URL = 'https://dev-admin.jupico.com';
  config.VGS_VAULT = 'tnthkove3uq';
  config.VGS_SCRIPT = 'https://js.verygoodvault.com/vgs-collect/2.7.0/vgs-collect.js';
  config.TOKENIZATION_URL = '/v1/transactions/creditcard/tokenization/';
  config.BYPASS_COMMONS_LOGIN = false;
  config.MIDDESK_BASE_BUSINESS_URL = 'https://app.middesk.com/businesses';
  document.title = 'Jupico Backoffice';
}
//DEV ENV2
else if (hostname === 'dev-payments.payfac.io') {
  config.ENV = 'development';
  config.ROOT_API = 'https://dev-payments-api.payfac.io/api/v1';
  config.HOSTED_PAGES_URL = 'https://dev-payments-hosted-pages.payfac.io';
  config.MICROSERVICES_URL = 'https://dev-platform.payfac.io/v1';
  config.ONBOARDING_URL = 'https://dev-payments-onboarding.payfac.io';
  config.PAYMENT_BACKOFFICE_URL = 'https://dev-payments.payfac.io';
  config.CAPITAL_BACKOFFICE_URL = 'https://dev-capital.payfac.io';
  config.COMMONS_BACKOFFICE_URL = 'https://dev-accounts.payfac.io';
  config.ADMIN_BACKOFFICE_URL = 'https://dev-admin.payfac.io';
  config.VGS_VAULT = 'tntkkrpdnva';
  config.VGS_SCRIPT = 'https://js.verygoodvault.com/vgs-collect/2.7.0/vgs-collect.js';
  config.TOKENIZATION_URL = '/v1/transactions/creditcard/tokenization/';
  config.BYPASS_COMMONS_LOGIN = false;
  config.MIDDESK_BASE_BUSINESS_URL = 'https://app.middesk.com/businesses';
  document.title = 'Payfac Backoffice';
}
//DEV ENV3
else if (hostname === 'local-payments.jupiterhq.com') {
  config.ENV = 'local';
  config.ROOT_API = 'http://local-payments-api.jupiterhq.com/api/v1';
  config.HOSTED_PAGES_URL = 'http://local-payments-hosted-pages.jupiterhq.com';
  config.MICROSERVICES_URL = 'https://local-payments-platform.jupiterhq.com/v1';
  config.ONBOARDING_URL = 'http://local-payments-onboarding.jupiterhq.com';
  config.PAYMENT_BACKOFFICE_URL = 'http://local-payments.jupiterhq.com';
  config.ACH_BACKOFFICE_URL = 'https://local-ach.jupiterhq.com';
  config.CAPITAL_BACKOFFICE_URL = 'https://local-capital.jupiterhq.com';
  config.COMMONS_BACKOFFICE_URL = 'http://local-accounts.jupiterhq.com';
  config.ADMIN_BACKOFFICE_URL = 'http://local-admin.jupiterhq.com';
  config.VGS_VAULT = 'tntanuqqlt3';
  config.PLAID_PRODUCTS = 'auth';
  config.PLAID_ENV = 'sandbox';
  config.PLAID_PUBLIC_KEY = '46225d2c76ead505b25cdc1fad27d0';
  config.PLAID_COUNTRY_CODES = 'US';
  config.VGS_VAULT = 'tntkkrpdnva';
  config.VGS_SCRIPT = 'https://js.verygoodvault.com/vgs-collect/2.7.0/vgs-collect.js';
  config.TOKENIZATION_URL = '/v1/transactions/creditcard/tokenization/';
  config.BYPASS_COMMONS_LOGIN = true;
  config.MIDDESK_BASE_BUSINESS_URL = 'https://app.middesk.com/businesses';
  document.title = 'Jupico Backoffice';
  //DEV ENV4
} else if (hostname === 'local-payments.jupico.com') {
  config.ENV = 'local';
  config.ROOT_API = 'http://local-payments-api.jupico.com/api/v1';
  config.HOSTED_PAGES_URL = 'http://local-payments-hosted-pages.jupico.com';
  config.MICROSERVICES_URL = 'https://local-payments-platform.jupico.com/v1';
  config.ONBOARDING_URL = 'http://local-payments-onboarding.jupico.com';
  config.PAYMENT_BACKOFFICE_URL = 'http://local-payments.jupico.com';
  config.ACH_BACKOFFICE_URL = 'https://local-ach.jupico.com';
  config.CAPITAL_BACKOFFICE_URL = 'https://local-capital.jupico.com';
  config.COMMONS_BACKOFFICE_URL = 'http://local-accounts.jupico.com';
  config.ADMIN_BACKOFFICE_URL = 'http://local-admin.jupico.com';
  config.VGS_VAULT = 'tntanuqqlt3';
  config.PLAID_PRODUCTS = 'auth';
  config.PLAID_ENV = 'sandbox';
  config.PLAID_PUBLIC_KEY = '46225d2c76ead505b25cdc1fad27d0';
  config.PLAID_COUNTRY_CODES = 'US';
  config.VGS_VAULT = 'tntkkrpdnva';
  config.VGS_SCRIPT = 'https://js.verygoodvault.com/vgs-collect/2.7.0/vgs-collect.js';
  config.TOKENIZATION_URL = '/v1/transactions/creditcard/tokenization/';
  config.BYPASS_COMMONS_LOGIN = true;
  config.MIDDESK_BASE_BUSINESS_URL = 'https://app.middesk.com/businesses';
  document.title = 'Jupico Backoffice';
  // PROD LOCAL STATION
}
// PROD LOCAL STATION
else if (hostname === 'local-payments.payfac.io') {
  config.ENV = 'local';
  config.ROOT_API = 'http://local-payments-api.payfac.io/api/v1';
  config.HOSTED_PAGES_URL = 'http://local-payments-hosted-pages.payfac.io';
  config.MICROSERVICES_URL = 'http://local-payments-platform.keybank/v1';
  config.ONBOARDING_URL = 'http://local-payments-onboarding.payfac.io';
  config.PAYMENT_BACKOFFICE_URL = 'http://local-payments.payfac.io';
  config.ACH_BACKOFFICE_URL = 'http://local-ach.payfac.io';
  config.CAPITAL_BACKOFFICE_URL = 'http://local-capital.payfac.io';
  config.COMMONS_BACKOFFICE_URL = 'http://local-accounts.payfac.io';
  config.ADMIN_BACKOFFICE_URL = 'http://local-admin.payfac.io';
  config.VGS_VAULT = 'tntkkrpdnva';
  config.VGS_SCRIPT = 'https://js.verygoodvault.com/vgs-collect/2.7.0/vgs-collect.js';
  config.BYPASS_COMMONS_LOGIN = true;
  config.TOKENIZATION_URL = '/v1/transactions/creditcard/tokenization/';
  config.MIDDESK_BASE_BUSINESS_URL = 'https://app.middesk.com/businesses';
  document.title = 'Payfac Backoffice';
} else {
  console.log('unknown domain using default local');
  config.ENV = 'local';
  config.ROOT_API = 'http://local-payments-api.jupiterhq.com/api/v1';
  config.HOSTED_PAGES_URL = 'http://local-payments-hosted-pages.jupiterhq.com';
  config.MICROSERVICES_URL = 'https://local-payments-platform.jupiterhq.com/v1';
  config.ONBOARDING_URL = 'http://local-payments-onboarding.jupiterhq.com';
  config.PAYMENT_BACKOFFICE_URL = 'https://local-payments.jupiterhq.com';
  config.ACH_BACKOFFICE_URL = 'https://local-ach.jupiterhq.com';
  config.CAPITAL_BACKOFFICE_URL = 'https://local-capital.jupiterhq.com';
  config.COMMONS_BACKOFFICE_URL = 'http://local-accounts.jupiterhq.com';
  config.ADMIN_BACKOFFICE_URL = 'https://local-admin.jupiterhq.com';
  config.VGS_VAULT = 'tntkkrpdnva';
  config.PLAID_PRODUCTS = 'auth';
  config.PLAID_ENV = 'sandbox';
  config.BASE_DOMAIN = 'localhost';
  config.PLAID_PUBLIC_KEY = '46225d2c76ead505b25cdc1fad27d0';
  config.PLAID_COUNTRY_CODES = 'US';
  config.VGS_SCRIPT = 'https://js.verygoodvault.com/vgs-collect/2.7.0/vgs-collect.js';
  config.TOKENIZATION_URL = '/v1/transactions/creditcard/tokenization/';
  config.BYPASS_COMMONS_LOGIN = true;
  config.MIDDESK_BASE_BUSINESS_URL = 'https://app.middesk.com/businesses';
  document.title = 'Jupico Backoffice';
}

export default config;
