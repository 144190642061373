import axios from 'axios';
import qs from 'qs';
import { set } from 'vue';
import { formatDateSelectors } from '@commons/helpers/date';
import { arrayBufferToJson } from '../helpers/utils';

// Mutation types
const SET_TRANSACTIONS = 'reporting/SET_TRANSACTIONS';
const SET_TRANSACTION_TIMELINE = 'reporting/SET_TRANSACTION_TIMELINE';
const SET_TRANSACTION_DETAIL = 'reporting/SET_TRANSACTION_DETAIL';
const SET_PAYOUTS_INSTRUCTIONS = 'reporting/SET_PAYOUTS_INSTRUCTIONS';
const SET_LEDGER_ACCOUNTS = 'reporting/SET_LEDGER_ACCOUNTS';
const SET_LEDGER_ENTRIES = 'reporting/SET_LEDGER_ENTRIES';
const SET_LEDGER_INITIAL_BALANCE = 'reporting/SET_LEDGER_INITIAL_BALANCE';
const SET_PAYOUTS_MERCHANT = 'reporting/SET_PAYOUTS_MERCHANT';
const SET_FEES = 'reporting/SET_FEES';
const SET_DISPUTES = 'reporting/GET_DISPUTES';
const SET_CHARTS_LINES = 'reporting/SET_CHARTS_LINES';
const SET_TRANSACTIONS_REPORT_DATE = 'reporting/SET_TRANSACTIONS_REPORT_DATE';
const SET_DISPUTES_REPORT_DATE = 'reporting/SET_DISPUTES_REPORT_DATE';
const SET_PROCESSOR_SESSION = 'reporting/SET_PROCESSOR_SESSION';
const SET_BALANCE = 'reporting/SET_BALANCE';
const SET_TRANSACTIONS_STATS = 'reporting/SET_TRANSACTIONS_STATS';
const SET_TOKEN_STATS = 'reporting/SET_TOKEN_STATS';

const ENDPOINTS = {
  GET_TRANSACTIONS: 'reporting/transactions',
  GET_DISPUTES: 'reporting/disputes',
  GET_CHARTS_LINES: 'reporting/disputes/chartsLines',
  GET_TRANSACTION_TIMELINE: 'reporting/transactions/:transactionId/timeline',
  GET_PAYOUTS_INSTRUCTIONS: 'reporting/payouts/instructions',
  GET_PAYOUTS_MERCHANT: 'reporting/payouts/merchant',
  GET_FEES: 'reporting/commissionfees',
  GET_LEDGER_ACCOUNTS: 'reporting/ledger',
  GET_LEDGER_ENTRIES: 'reporting/ledger/entries',
  GET_PROCESSOR_SESSION: 'reporting/monitor/processorsession',
  GET_PROCESSOR_SESSION_BY_ID: 'reporting/monitor/processorsession/:sessionId',
  GET_BALANCE: 'reporting/balance',
  GET_TX_STATS: 'reporting/transactions/stats',
  GET_TOKEN_STATS: 'reporting/transactions/token-stats'
};

function initialState() {
  return {
    transactions: {},
    payoutsInstructions: {},
    payoutsMerchant: {},
    fees: {},
    timeline: {},
    transactionDetail: {},
    disputes: {},
    chartsLines: {},
    transactionReportDate: {},
    disputesReportDate: {},
    ledgerAccounts: {},
    ledgerEntries: {},
    processorSession: [],
    balance: {},
    txStats: {},
    tokenStats: {}
  };
}
// initial state
const state = initialState();

// actions
const actions = {
  CLEAR_TRANSACTIONS({ commit }) {
    commit(SET_TRANSACTIONS, {});
  },
  async GET_TRANSACTIONS({ commit }, params) {
    try {
      if (params.subMerchantId?.length && typeof params.subMerchantId != 'string') {
        params.subMerchantId = params.subMerchantId.join(',');
      }
      if (params.processing?.batchDate && !params.processing.batchDate.startsWith('"')) {
        params.processing.batchDate = `"${params.processing.batchDate}"`;
      }

      if (params.numberInputsPrefixes) delete params.numberInputsPrefixes;
      const { noStore } = params;
      if (noStore) {
        delete params.noStore;
      }

      params = formatDateSelectors(params);
      const response = await axios.get(ENDPOINTS.GET_TRANSACTIONS, {
        params,
        paramsSerializer: params => qs.stringify(params, { indices: false }),
        cancelPreviousRequests: true
      });

      if (noStore) {
        return response.data;
      }
      commit(SET_TRANSACTIONS, {
        totals: response.data.totals,
        data: response.data.data
      });
    } catch (error) {
      if (axios.isCancel(error)) return;
      else
        throw (
          error.message ||
          'Something went wrong fetching transactions. Please try again later.'
        );
    }
  },
  async DOWNLOAD_TRANSACTIONS({ commit }, data) {
    const fileName = data.declined ? 'declined_transactions' : 'transactions';
    delete data.fileName;
    try {
      const params = formatDateSelectors(data);
      const response = await axios.get(ENDPOINTS.GET_TRANSACTIONS, {
        responseType: 'blob',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/csv'
        },
        params: { ...params, export: 'csv' },
        paramsSerializer: params => qs.stringify(params, { indices: false }),
        cancelPreviousRequests: true
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${fileName}.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);

      return { success: true };
    } catch (error) {
      const errorResponse = arrayBufferToJson(error.response?.data);
      throw (
        errorResponse?.message ??
        'Something went wrong with transactions. Please try again later.'
      );
    }
  },
  GET_TRANSACTION(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(ENDPOINTS.GET_TRANSACTIONS, { params: data })
        .then(response => resolve(response.data.data[0]))
        .catch(() => {
          reject('GET_TRANSACTIONS_ERROR_MESSAGE');
        });
    });
  },
  GET_LEDGER_ACCOUNTS({ commit }, data) {
    const params = formatDateSelectors(data);
    return new Promise((resolve, reject) => {
      axios
        .get(ENDPOINTS.GET_LEDGER_ACCOUNTS, { params })
        .then(response => {
          commit(SET_LEDGER_ACCOUNTS, response.data.data);
          resolve(response.data.data);
        })
        .catch(() => reject('GET_LEDGER_ACCOUNTS_ERROR_MESSAGE'));
    });
  },
  GET_BALANCE({ commit }, data) {
    if (Array.isArray(data.subMerchantId))
      data.subMerchantId = data.subMerchantId.join(',');
    return new Promise((resolve, reject) => {
      axios
        .get(ENDPOINTS.GET_BALANCE, { params: data })
        .then(response => {
          commit(SET_BALANCE, response.data.data);
          resolve();
        })
        .catch(() => reject('GET_BALANCE_ERROR_MESSAGE'));
    });
  },
  CLEAR_LEDGER_ACCOUNTS({ commit }) {
    commit(SET_LEDGER_ACCOUNTS, []);
  },
  GET_LEDGER_ENTRIES({ commit }, data) {
    const params = formatDateSelectors(data);

    params.getData = true;
    params.getInitialBalance = false;

    return new Promise((resolve, reject) => {
      axios
        .get(ENDPOINTS.GET_LEDGER_ENTRIES, { params })
        .then(response => {
          commit(SET_LEDGER_ENTRIES, {
            total: response.data.totals.total,
            data: response.data.data,
            count: response.data.totals.count
          });
          resolve(response.data.data);
        })
        .catch(() => reject('GET_LEDGER_ENTRIES_ERROR_MESSAGE'));
    });
  },
  GET_LEDGER_INITIAL_BALANCE({ commit }, data) {
    const params = formatDateSelectors(data);

    params.getData = false;
    params.getInitialBalance = true;

    return new Promise((resolve, reject) => {
      axios
        .get(ENDPOINTS.GET_LEDGER_ENTRIES, { params })
        .then(response => {
          commit(SET_LEDGER_INITIAL_BALANCE, response.data.totals.initialBalance);
          resolve(response.data.data);
        })
        // .catch(() => reject('GET_LEDGER_ENTRIES_ERROR_MESSAGE'));
        .catch(error => {
          console.error(error);
          reject('GET_LEDGER_ENTRIES_ERROR_MESSAGE');
        });
    });
  },
  CLEAR_LEDGER_ENTRIES({ commit }) {
    commit(SET_LEDGER_ENTRIES, {
      // total: 0,
      // data: [],
      // count: 0,
      // initialBalance: 0
    });
  },
  GET_PAYOUTS_INSTRUCTIONS({ commit }, data) {
    const params = formatDateSelectors(data);
    return new Promise((resolve, reject) => {
      axios
        .get(ENDPOINTS.GET_PAYOUTS_INSTRUCTIONS, {
          params,
          paramsSerializer: params =>
            qs.stringify(params, { allowDots: false, indices: false })
        })
        .then(response => {
          commit(SET_PAYOUTS_INSTRUCTIONS, {
            data: response.data.data,
            count: response.data.totals.count
          });
          resolve();
        })
        .catch(() => reject());
    });
  },
  GET_PAYOUTS_MERCHANT({ commit }, data) {
    const params = formatDateSelectors(data);
    if (data.numberInputsPrefixes) delete params.numberInputsPrefixes;
    return new Promise((resolve, reject) => {
      axios
        .get(ENDPOINTS.GET_PAYOUTS_MERCHANT, {
          params,
          paramsSerializer: params =>
            qs.stringify(params, { allowDots: false, indices: false })
        })
        .then(response => {
          commit(SET_PAYOUTS_MERCHANT, {
            count: response.data.totals.count,
            data: response.data.data,
            totals: response.data.totals
          });
          resolve();
        })
        .catch(() => reject());
    });
  },
  GET_DISPUTES({ commit }, data) {
    const params = formatDateSelectors(data);
    if (params.submerchantId?.length && typeof params.submerchantId != 'string') {
      params.submerchantId = params.submerchantId.join(',');
    }
    return new Promise((resolve, reject) => {
      axios
        .get(ENDPOINTS.GET_DISPUTES, {
          params,
          paramsSerializer: params =>
            qs.stringify(params, { allowDots: false, indices: false })
        })
        .then(response => {
          commit(SET_DISPUTES, {
            count: response.data.totals.count,
            data: response.data.data
          });
          resolve(response.data.data);
        })
        .catch(() => reject());
    });
  },
  async DOWNLOAD_DISPUTES(context, data) {
    try {
      let params = formatDateSelectors(data);
      delete params.dateRange;
      const response = await axios.get(ENDPOINTS.GET_DISPUTES, {
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/csv'
        },
        params: { exportType: 'csv', ...params },
        paramsSerializer: params => qs.stringify(params, { allowDots: true })
      });
      const blobObject = new Blob([response.data], { type: 'octet/stream' });
      const url = window.URL.createObjectURL(blobObject);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'disputes.csv');
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.log(error);
    }
  },
  GET_CHARTS_LINES({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(ENDPOINTS.GET_CHARTS_LINES, {
          params: data,
          paramsSerializer: params =>
            qs.stringify(params, { allowDots: true, indices: false })
        })
        .then(response => {
          commit(SET_CHARTS_LINES, {
            data: response.data.data,
            count: response.data.totals.count
          });
          resolve();
        })
        .catch(() => reject());
    });
  },
  async DOWNLOAD_PAYOUTS(context, data) {
    try {
      const params = formatDateSelectors(data);
      const response = await axios.get(ENDPOINTS.GET_PAYOUTS_INSTRUCTIONS, {
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/csv'
        },
        params: { ...params, export: 'csv' },
        paramsSerializer: params =>
          qs.stringify(params, { allowDots: true, indices: false })
      });

      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: 'octet/stream' })
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'payouts.csv');
      document.body.appendChild(link);
      link.click();
      return;
    } catch (error) {
      const errorResponse = arrayBufferToJson(error?.response?.data);
      throw (
        errorResponse?.message ?? 'Something went wrong payouts. Please try again later.'
      );
    }
  },
  async DOWNLOAD_PAYOUTS_MERCHANT(context, data) {
    try {
      const params = formatDateSelectors(data);
      const response = await axios.get(ENDPOINTS.GET_PAYOUTS_MERCHANT, {
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/csv'
        },
        params: { ...params, export: 'csv' },
        paramsSerializer: params =>
          qs.stringify(params, { allowDots: true, indices: false })
      });

      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: 'octet/stream' })
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'payouts_balance.csv');
      document.body.appendChild(link);
      link.click();
      return;
    } catch (error) {
      const errorResponse = arrayBufferToJson(error?.response?.data);
      throw (
        errorResponse?.message ??
        'Something went wrong payouts balances. Please try again later.'
      );
    }
  },
  CLEAR_PAYOUTS_INSTRUCTIONS({ commit }) {
    commit(SET_PAYOUTS_INSTRUCTIONS, {});
  },
  UPDATE_REPORTING_PAYOUT_ROW({ state, commit }, { row, index }) {
    let payoutsInstructions = JSON.parse(JSON.stringify(state.payoutsInstructions));
    payoutsInstructions.data[index] = row;
    commit(SET_PAYOUTS_INSTRUCTIONS, payoutsInstructions);
  },
  GET_FEES({ commit }, data) {
    const params = formatDateSelectors(data);
    if (params.fixedFee) {
      params['transaction.fixedFee'] = params.fixedFee;
      params['schedule.fixedFee'] = params.fixedFee;
      delete params.fixedFee;
    }
    return new Promise((resolve, reject) => {
      axios
        .get(ENDPOINTS.GET_FEES, {
          params,
          paramsSerializer: params =>
            qs.stringify(params, { allowDots: false, indices: false })
        })
        .then(response => {
          commit(SET_FEES, {
            data: response.data.data,
            count: response.data.totals.count
          });
          resolve();
        })
        .catch(() => reject());
    });
  },
  async DOWNLOAD_FEES(context, data) {
    try {
      const params = formatDateSelectors(data);
      if (params.fixedFee) {
        params['transaction.fixedFee'] = params.fixedFee;
        params['schedule.fixedFee'] = params.fixedFee;
        delete params.fixedFee;
      }
      const response = await axios.get(ENDPOINTS.GET_FEES, {
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/csv'
        },
        params: { ...params, export: 'csv' },
        paramsSerializer: params =>
          qs.stringify(params, { allowDots: true, indices: false })
      });

      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: 'octet/stream' })
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'fees.csv');
      document.body.appendChild(link);
      link.click();
      return;
    } catch (error) {
      console.log({ error });
      const errorResponse = arrayBufferToJson(error.response.data);
      throw (
        errorResponse?.message ??
        'Something went wrong downloading fee. Please try again later.'
      );
    }
  },
  CLEAR_FEES({ commit }) {
    commit(SET_FEES, {});
  },
  UPDATE_REPORTING_TRANSACTION_ROW({ state, commit }, { row, index }) {
    let transactions = JSON.parse(JSON.stringify(state.transactions));
    transactions.data[index] = row;
    commit(SET_TRANSACTIONS, transactions);
  },
  GET_TRANSACTION_TIMELINE({ commit }, transactionId) {
    return new Promise((resolve, reject) => {
      axios
        .get(ENDPOINTS.GET_TRANSACTION_TIMELINE.replace(':transactionId', transactionId))
        .then(response => {
          commit(SET_TRANSACTION_TIMELINE, response.data ? response.data.data : {});
          resolve();
        })
        .catch(() => reject());
    });
  },
  GET_TRANSACTION_FEES({ commit }, transactionId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${ENDPOINTS.GET_FEES}?transaction.transactionId='${transactionId}'`)
        .then(response => {
          commit(SET_TRANSACTION_DETAIL, response.data ? response.data.data[0] : {});
          resolve();
        })
        .catch(() => reject());
    });
  },
  CLEAR_TRANSACTION_TIMELINE({ commit }) {
    commit(SET_TRANSACTION_TIMELINE, {});
  },
  CLEAR_TRANSACTION_DETAIL({ commit }) {
    commit(SET_TRANSACTION_DETAIL, {});
  },
  SET_TRANSACTIONS_REPORT_DATE({ commit }, data) {
    commit(SET_TRANSACTIONS_REPORT_DATE, data);
  },
  SET_DISPUTES_REPORT_DATE({ commit }, data) {
    commit(SET_DISPUTES_REPORT_DATE, data);
  },
  GET_PROCESSOR_SESSION({ commit }) {
    try {
      return new Promise((resolve, reject) => {
        axios
          .get(ENDPOINTS.GET_PROCESSOR_SESSION)
          .then(response => {
            commit(SET_PROCESSOR_SESSION, response.data);
            resolve();
          })
          .catch(() => reject('GET_PROCESSOR_SESSION'));
      });
    } catch (error) {
      console.log({ error });
    }
  },
  GET_PROCESSOR_SESSION_BY_ID({ commit }, data) {
    const params = data;

    return new Promise((resolve, reject) => {
      axios
        .get(
          ENDPOINTS.GET_PROCESSOR_SESSION_BY_ID.replace(':sessionId', params.sessionId),
          {
            params,
            paramsSerializer: params => qs.stringify(params, { indices: false })
          }
        )
        .then(response => {
          commit(SET_PROCESSOR_SESSION, response.data);
          resolve();
        })
        .catch(() => reject('GET_PROCESSOR_SESSION_BY_ID'));
    });
  },
  async GET_TX_STATS({ commit }, params) {
    try {
      if (params.subMerchantId?.length && typeof params.subMerchantId != 'string') {
        params.subMerchantId = params.subMerchantId.join(',');
      }
      params = formatDateSelectors(params);
      const response = await axios.get(ENDPOINTS.GET_TX_STATS, {
        params,
        paramsSerializer: params => qs.stringify(params, { indices: false }),
        cancelPreviousRequests: true
      });

      commit(SET_TRANSACTIONS_STATS, {
        totals: response.data.totals,
        data: response.data.data
      });
    } catch (error) {
      if (axios.isCancel(error)) return;
      else
        throw (
          error.message ||
          'Something went wrong fetching transactions. Please try again later.'
        );
    }
  },
  async GET_TOKEN_STATS({ commit }, params) {
    try {
      if (params.subMerchantId?.length && typeof params.subMerchantId != 'string') {
        params.subMerchantId = params.subMerchantId.join(',');
      }
      params = formatDateSelectors(params);
      const response = await axios.get(ENDPOINTS.GET_TOKEN_STATS, {
        params,
        paramsSerializer: params => qs.stringify(params, { indices: false }),
        cancelPreviousRequests: true
      });

      commit(SET_TOKEN_STATS, {
        totals: response.data.totals,
        data: response.data.data
      });
    } catch (error) {
      if (axios.isCancel(error)) return;
      else
        throw (
          error.message ||
          'Something went wrong fetching token stats. Please try again later.'
        );
    }
  }
};

// getters
const getters = {
  getDisputes: state => state.disputes,
  getChartsLines: state => state.chartsLines,
  getReportingTransactions: state => state.transactions,
  getReportingPayoutsInstructions: state => state.payoutsInstructions,
  getReportingPayoutsMerchant: state => state.payoutsMerchant,
  getLedgerAccounts: state => state.ledgerAccounts,
  getLedgerEntries: state => state.ledgerEntries,
  getReportingFees: state => state.fees,
  getTransactionTimeline: state => state.timeline,
  getTransactionDetail: state => state.transactionDetail,
  getTransactionsReportDate: state => state.transactionReportDate,
  getDisputesReportDate: state => state.disputesReportDate,
  getProcessorSession: state => state.processorSession,
  getBalance: state => state.balance,
  getTransactionsStats: state => state.txStats,
  getTokenStats: state => state.tokenStats
};

// mutations
const mutations = {
  [SET_TRANSACTIONS](state, data) {
    state.transactions = data;
  },
  [SET_PAYOUTS_INSTRUCTIONS](state, data) {
    state.payoutsInstructions = data;
  },
  [SET_LEDGER_ACCOUNTS](state, data) {
    state.ledgerAccounts = data;
  },
  [SET_LEDGER_ENTRIES](state, data = {}) {
    state.ledgerEntries = data;
  },
  [SET_LEDGER_INITIAL_BALANCE](state, data) {
    set(state.ledgerEntries, 'initialBalance', data);
  },
  [SET_PAYOUTS_MERCHANT](state, data) {
    state.payoutsMerchant = data;
  },
  [SET_FEES](state, data) {
    state.fees = data;
  },
  [SET_DISPUTES](state, data) {
    state.disputes = data;
  },
  [SET_CHARTS_LINES](state, data) {
    state.chartsLines = data;
  },
  [SET_TRANSACTION_TIMELINE](state, data) {
    state.timeline = data;
  },
  [SET_TRANSACTION_DETAIL](state, data) {
    state.transactionDetail = data;
  },
  [SET_TRANSACTIONS_REPORT_DATE](state, data) {
    state.transactionReportDate = data;
  },
  [SET_DISPUTES_REPORT_DATE](state, data) {
    state.disputesReportDate = data;
  },
  [SET_PROCESSOR_SESSION](state, data = {}) {
    state.processorSession = data;
  },
  [SET_BALANCE](state, data) {
    state.balance = data;
  },
  [SET_TRANSACTIONS_STATS](state, data) {
    state.txStats = data;
  },
  [SET_TOKEN_STATS](state, data) {
    state.tokenStats = data;
  }
};

export default {
  state: { ...state },
  actions,
  getters,
  mutations
};
