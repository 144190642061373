import { INVOICE_STATUS } from '@commons/data/constants';
import { formatDateSelectors } from '@commons/helpers/date';
import axios from 'axios';
import qs from 'qs';

// Endpoints
const INVOICES_ENDPOINT = 'backoffice/invoices';
const SINGLE_INVOICE_ENDPOINT = `${INVOICES_ENDPOINT}/:invoiceId`;
const SEND_INVOICE_ENDPOINT = `${INVOICES_ENDPOINT}/:invoiceId/send`;
const VOID_INVOICE_ENDPOINT = `${INVOICES_ENDPOINT}/:invoiceId/void`;

const INVOICE_PREVIEWS_ENDPOINT = `backoffice/previews`;
const INVOICE_PREVIEW_ENDPOINT = `${INVOICE_PREVIEWS_ENDPOINT}/:previewId`;

const TOTAL_CHARTS_ENDPOINT = `${INVOICES_ENDPOINT}/stats`;

const SET_INVOICES = 'invoices/SET_INVOICES';
const UPDATE_INVOICE = 'invoices/UPDATE_INVOICE';

// initial state
const state = { invoices: null };

// actions
const actions = {
  async ADD_INVOICE(context, data) {
    try {
      const response = await axios.post(INVOICES_ENDPOINT, data);
      return response.data;
    } catch (e) {
      if (e.response?.data?.message) throw e.response.data.message;
      else throw new Error('Error creating Invoice');
    }
  },
  async ADD_PREVIEW_INVOICE(context, data) {
    const tempInvoice = {
      subMerchantId: data.subMerchantId,
      customerId: data.customerId,
      invoiceNumber: data.invoiceNumber,
      date: data.date,
      dueDate: data.dueDate,
      paymentTerm: data.paymentTerm,
      items: data.items,
      totalAmount: data.totalAmount,
      taxAmount: data.taxAmount,
      status: data.status || INVOICE_STATUS.DRAFT,

      shareMethods: data.shareMethods || [],
      email: data.email,
      mobileNumber: data.mobileNumber
    };
    try {
      const response = await axios.post(INVOICE_PREVIEWS_ENDPOINT, {
        modelName: 'invoices',
        modelId: data.invoiceId,
        data: tempInvoice
      });
      return response.data;
    } catch (e) {
      if (e.response?.data?.message) throw e.response.data.message;
      else throw new Error('Error creating Preview Invoice');
    }
  },
  async EDIT_INVOICE({ commit }, data) {
    try {
      const response = await axios.put(
        SINGLE_INVOICE_ENDPOINT.replace(':invoiceId', data.invoiceId),
        data
      );
      commit(UPDATE_INVOICE, response.data?.data);
      return response.data;
    } catch (e) {
      if (e.response?.data?.message) throw e.response.data.message;
      else throw new Error('Error while editing the Invoice. Try again later.');
    }
  },
  async EDIT_PREVIEW_INVOICE({ commit }, data) {
    const { previewId } = data;
    const params = {
      subMerchantId: data.subMerchantId,
      customerId: data.customerId,
      invoiceNumber: data.invoiceNumber,
      date: data.date,
      dueDate: data.dueDate,
      paymentTerm: data.paymentTerm,
      items: data.items,
      totalAmount: data.totalAmount,
      taxAmount: data.taxAmount
    };
    if (data.status) {
      params.status = data.status;
    }
    try {
      const response = await axios.put(
        INVOICE_PREVIEW_ENDPOINT.replace(':previewId', previewId),
        params
      );
      const temp = response.data?.data;
      commit(UPDATE_INVOICE, temp?.data);
      return response.data;
    } catch (e) {
      if (e.response?.data?.message) throw e.response.data.message;
      else throw new Error('Error while editing the Preview Invoice. Try again later.');
    }
  },
  async SEND_INVOICE({ commit, state }, data) {
    const { invoiceId, subMerchantId } = data;
    try {
      const response = await axios.post(
        SEND_INVOICE_ENDPOINT.replace(':invoiceId', invoiceId),
        { subMerchantId }
      );
      if (response.data.success) {
        const updatedInvoice = {
          ...state.invoices.data.find(invoice => invoice.invoiceId === invoiceId),
          isSent: true
        };
        commit(UPDATE_INVOICE, updatedInvoice);
        return {
          success: response.data.success,
          message: response.data.message,
          data: updatedInvoice
        };
      } else {
        throw new Error(response.data.message);
      }
    } catch (e) {
      if (e.response?.data?.message) throw e.response.data.message;
      else throw new Error('Error while sending the Invoice. Try again later.');
    }
  },
  async VOID_INVOICE({ commit, state }, { invoiceId, subMerchantId }) {
    try {
      const response = await axios.post(
        VOID_INVOICE_ENDPOINT.replace(':invoiceId', invoiceId),
        { subMerchantId }
      );
      if (response.data.success) {
        const updatedInvoice = {
          ...state.invoices.data.find(invoice => invoice.invoiceId === invoiceId),
          status: INVOICE_STATUS.VOIDED
        };
        commit(UPDATE_INVOICE, updatedInvoice);
        return {
          success: true,
          message: 'Invoice voided',
          data: updatedInvoice
        };
      }
      throw new Error(response.data.message);
    } catch (e) {
      if (e.response?.data?.message) throw e.response.data.message;
      else throw new Error('Error while voiding the Invoice. Try again later.');
    }
  },
  async DELETE_INVOICE({ commit, state }, data) {
    const { invoiceId, subMerchantId } = data;
    try {
      const response = await axios.delete(
        SINGLE_INVOICE_ENDPOINT.replace(':invoiceId', invoiceId),
        { data: { subMerchantId } }
      );
      if (response.data.success) {
        const updatedInvoice = {
          ...state.invoices.data.find(invoice => invoice.invoiceId === invoiceId),
          isDeleted: true
        };
        commit(UPDATE_INVOICE, updatedInvoice);
        return updatedInvoice;
      }
      throw new Error(response.data.message);
    } catch (e) {
      if (e.response?.data?.message) throw e.response.data.message;
      else throw new Error('Error while deleting the Invoice. Try again later.');
    }
  },
  async GET_INVOICES({ commit }, data) {
    if (data.numberInputsPrefixes) delete data.numberInputsPrefixes;
    try {
      // Clear old data first
      commit(SET_INVOICES, { count: 0, data: [] });

      data = formatDateSelectors(data);
      const response = await axios.get(INVOICES_ENDPOINT, {
        params: data,
        paramsSerializer: params => qs.stringify(params, { indices: false })
      });
      response.data.data.forEach((item) => {
        item.logs.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      });
      commit(SET_INVOICES, {
        count: response.data.count,
        data: response.data.data
      });
    } catch (e) {
      if (e.response?.data?.message) throw e.response.data.message;
      else throw new Error('Error while getting the Invoices. Try again later.');
    }
  },
  async GET_INVOICE({ commit }, invoiceId) {
    try {
      const response = await axios.get(
        SINGLE_INVOICE_ENDPOINT.replace(':invoiceId', invoiceId)
      );
      return response.data;
    } catch (e) {
      if (e.response?.data?.message) throw e.response.data.message;
      else throw new Error('Error while get the Invoice. Try again later.');
    }
  },
  DELETE_INVOICES_FROM_LIST({ commit, state }, invoiceId) {
    let invoices = JSON.parse(JSON.stringify(state.invoices));
    const invoiceIndex = invoices.data.findIndex(
      invoice => invoice.invoiceId === invoiceId
    );
    if (invoiceIndex > -1) {
      invoices.data.splice(invoiceIndex, 1);
      commit(SET_INVOICES, invoices);
    }
  },
  CLEAR_INVOICES({ commit }) {
    commit(SET_INVOICES, {});
  },
  async TOTAL_CHART({ commit }, params) {
    try {
      const response = await axios.get(TOTAL_CHARTS_ENDPOINT, {}); //TODO: add params
      return response;
    } catch (error) {
      if (error.response?.data?.message) throw error.response.data.message;
      else throw new Error('Error while getting the total chart. Try again later.');
    }
  },
  async DOWNLOAD_INVOICES({ commit }, params) {
    const filename = params.fileName || 'invoices';
    delete params.fileName;
    try {
      const response = await axios.get(INVOICES_ENDPOINT, {
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/csv'
        },
        params: { ...params, export: 'csv' },
        paramsSerializer: params =>
          qs.stringify(params, { allowDots: true, indices: false })
      });
      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: 'octet/stream' })
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${filename}.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);

      return response;
    } catch (error) {
      if (error.response?.data) {
        const errorResponse = new TextDecoder().decode(error.response.data);
        try {
          const parsedError = JSON.parse(errorResponse);
          throw parsedError.message || 'Error downloading invoices CSV';
        } catch {
          throw 'Error downloading invoices CSV';
        }
      }
      throw new Error('Error downloading invoices CSV');
    }
  }
};

const getters = {
  getInvoices: state => state.invoices
};

const mutations = {
  [SET_INVOICES](state, data) {
    state.invoices = data;
  },
  [UPDATE_INVOICE](state, updatedInvoice) {
    if (state.invoices?.data) {
      const index = state.invoices.data.findIndex(
        invoice => invoice.invoiceId === updatedInvoice.invoiceId
      );
      if (index === -1) {
        state.invoices.data.push(updatedInvoice);
      } else {
        state.invoices.data.splice(index, 1, updatedInvoice);
      }
    } else {
      state.invoices = {
        data: [updatedInvoice]
      };
    }
  }
};

export default {
  state: { ...state },
  actions,
  getters,
  mutations
};
